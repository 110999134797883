#login-page {
    display: flex;
    justify-content: center;
    align-items: center;

    .pod {
        padding: {
            top: 40px;
            bottom: 40px;
        }
    }
}