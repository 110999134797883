#assessment-info {
    padding: 32px 0;
    overflow-x: hidden;
    color: var(--people-primary-deep);

    h1 {
        font-size: 200%;
    }

    h2 {
        font-size: 160%;
    }


    .sub-heading-wrapper {
        width: 100%;
        margin: 0 10%;

        h3 {
            margin: 64px 10% 16px 10%;
            font-weight: 500;
            font-size: 150%;

            @include screenSizeMobileFirst(lgMobile) {
                margin: 64px 11% 16px 11%;
            }
        }
    }

    p {
        font-size: 140%;
    }

    .pod {
        margin: 0 10%;
    }

    .button-wrapper {
        width: 100%;
        flex: 1 1 auto;
        display: flex;
        align-items: flex-end;

        @include screenSizeMobileFirst(lgMobile) {
            max-width: 30em;
        }

        .button-container {
            margin: 0 10%;  
            flex-grow: 1;
        }
    }
    .assessment-info-img-container {
        background-size: cover;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 300px;
        font-size: 29px;
        font-weight: bolder;
        font-family: Inter;
        color: white;
    }

    .stat-pillar-wrapper {
        display: flex;
        flex-direction: column;
        height: 300px;

        p {
            font-family: Inter;
            font-size: 14px;
            width: 240px;

            &.title {
                font-weight: bold;
            } 
        }
    }

    .stat-pillar {
        height: 60px;
        width: 4px;
        background-color: red;
    }

    .carousel-container {
        flex: 0;
        padding: 0 10%;
        display: flex;
        justify-content: center;
        max-width: 100vw;

        .swiper {
            padding: 0 15px;

            @include screenSizeMobileFirst(lgMobile) {
                padding: unset;
            }
        }

        .pod {
            margin: 0 5%;
            h4 {
                font-size: 180%;
                font-weight: 500;
                margin-bottom: 24px;
            }
            color: white;

            .icon-wrapper {
                width: 40px;
                height: 40px;
                margin-bottom: 24px;
                border-radius: 50%;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 200%;
                    font-weight: 500;
                    margin: 0;
                }
            }
        }

        .swiper-slide {

            .pod {
                min-height: 360px;
            }

            &:first-child {

                .pod {
                    background-color: var(--know-dark);

                    i {
                        color:  var(--know-dark);
                    }
                }
            }
            
            &:nth-child(2) {
                .pod {
                    background-color: var(--grow-dark);

                    i {
                        color:  var(--grow-dark);
                    }
                }
            }
            
            &:nth-child(3) {
                .pod {
                    background-color: var(--be-light);

                    i {
                        color:  var(--be-light);
                    }
                }
            }
            
            &:last-child {
                .pod {
                    background-color: var(--play-light);

                    i {
                        color:  var(--play-light);
                    }
                }
            }
        }
    }
}