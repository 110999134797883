@import 'variables.scss';

html, body, #root, #web-body {
    height: 100%;
    max-width: 100vw;
    overflow: hidden;
    @include mainFont();
}

// wrapper
#web-body {
    display: flex;
    flex-direction: column;
    background: #F5F4F5;
    color: black;
    font-size: 10px;
}

#web-wrapper {
    display: flex;
    flex: 1;
    overflow: auto;
    overscroll-behavior: none;
}

.container {
    flex: 1;
    margin: 0 auto;
}

.full-page {
    min-height: calc(100vh - 60px);
}

.area-padding {
    padding: 25px;
}

.area-padding-vertical {
    padding: 25px 0;
}

.area-padding-horizontal {
    padding: 0 25px;
}

.thin-divider {
    height: 1px;
    width: 100%;
    background-color: var(--decathlon-tertiary)
}

.underline {
    text-decoration: underline;
}

.header-text {
    font-size: 20px;
}

.mt-8 {
    margin-top: 8px;
}

// toasts
.Toastify__toast-body > div:last-child {
    font-size: 0.875rem;
}

@include screenSizeMobileFirst(lgDesktop) {
    .header-text {
        font-size: 30px;
    }
}

// components
@import 'components/button.scss';
@import 'components/chart.scss';
@import 'components/form.scss';
@import 'components/header.scss';
@import 'components/modal.scss';
@import 'components/pod.scss';
@import 'components/progressBar.scss';
@import 'components/table.scss';
@import 'components/typography.scss';
@import 'components/util.scss';
@import 'components/carousel.scss';
@import 'components/bubbleHeader.scss';
@import 'components/paginationDots.scss';
@import 'components/secondaryBackground.scss';

//pages
@import 'pages/home.scss';
@import 'pages/assessment.scss';
@import 'pages/assessment-complete.scss';
@import 'pages/check-results.scss';
@import 'pages/peers-invitation.scss';
@import 'pages/latest-assessment-details.scss';
@import 'pages/login.scss';
@import 'pages/compare-results.scss';
@import 'pages/assessment-info.scss';

//other
