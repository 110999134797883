#latest-assessment-details {

    
    .bubble-header {
        .content {
            padding: 10px 25px 0 25px;
        }
    }

    .pod {
        flex: 1;
        max-width: calc(100% - 50px);
        max-height: 66vh;
        display: flex;
        flex-direction: column;

        @include screenSizeMobileFirst(lgMobile) {
            max-width: 480px;
            max-height: calc(100vh - 320px);
        }

        .pod-button {
            font-size: 25px;
            background-color: transparent;
            padding: 10px;
        }
        .title-container {
            flex: 1;
            // width: 300px;
    
            .title {
                font-size: 30px;
                margin-left: 10px;
                margin-right: 10px;
                text-align: center;
                text-overflow: wrap;
            }
        }
    
        .chart-scroll-wrapper {
            flex: 1;
            overflow-y: auto;
            margin: 0.5em 0;
            // min-height: 50vh;
            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }
            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            .behaviour-result-chart-container {
                min-height: 80px;
            }
        }
    }
}
