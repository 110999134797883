// header
.header {
    width: 100%;
    height: 80px;
    background-color: var(--background-primary);
    z-index: 100;

    .container {
        height: 80px;
    }

    // logo
    .logo {
        img {
            display: block;
            height: 50px;
        }
    }

    .button-area {
        width: 30px;
        height: 30px;
    }

    button {
        width: 100%;
        height: 100%;
        background-color: transparent;
        color: var(--decathlon-typography);
        font-size: 220%;
    }

    .dropdown-menu-container {
        // border: 1px solid white;
        position: absolute;
        z-index: 100;
        top: 140%;
        right: 0;
        min-width: 15em;
        max-height: 0;
        overflow: hidden;
        box-sizing: border-box;
        transition: max-height 0.2s ease;
        height: fit-content;
        background-color: var(--people-primary-deep);

        &.expanded {
            max-height: unset;
        }

        .menu-item {
            padding: 25px;
            color: white;
            font-size: 150%;

            i {
                margin-right: 0.5em;
            }
        }
    }

    @include screenSizeMobileFirst(smDesktop) {
        button {
            font-size: 300%;
        }
    }
}