// asterisk
.form-asterisk {
  color: var(--people-primary);
}

// error
.form-error {
  color: var(--highlight-form-error);
}

.form-generic-error {
  border-color: var(--highlight-form-error);
  background-color: var(--background-form-error);
}

// inputs, textarea and select
.form-input, .form-select {
  @include mainFont();
  color: var(--people-primary);
  background-color: var(--background-form-input);
  border-color: var(--stroke-form-input);

  &:disabled, &.disabled {
    background-color: var(--background-form-input-disabled);
  }

  &.error {
    border-color: var(--highlight-form-error);
  }
}

// input and textarea
.form-input {
  &:focus {
    border-color: var(--people-primary);
  }

  &::placeholder {
    color: var(--placeholder-form-input);
  }
}

// select
.form-select {
  padding: 11px 15px;

  .arrow {
    border-left-color: var(--stroke-form-input);
  }

  p {
    &.placeholder {
      color: var(--placeholder-form-input);
    }
  }

  i {
    color: var(--people-primary);
  }

  // multi select
  &.multi-select {
    .option {
      background-color: var(--background-form-multi-select-option);
    }
  }
}

// select options & search
.form-select-options {
  background-color: var(--background-form-input);
  border-color: var(--stroke-form-input);

  .options-list {
    .option {
      &:hover, &.active {
        background-color: var(--background-form-multi-select-option);
      }
    }
  }
}

// checkbox and radio buttons
.form-checkbox, .form-radio {
  .content {
    .outer-box {
      border-color: var(--stroke-form-input);
      background-color: var(--background-form-input);
    }

    &.disabled {
      .outer-box {
        background-color: var(--background-form-input-disabled);
      }
    }
  }
}

.form-radio {
  .content {
    .inner-box {
      background-color: var(--people-primary);
    }
  }
}

// datepicker
.form-datepicker {
  .calendar {
    border-left-color: var(--stroke-form-input);
  }

  i {
    color: var(--people-primary);
  }
}

// toggle select
.checkbox {
  input {
    &:checked {
      + label,
      + input + label {
        .outer {
          background-color: var(--people-primary);
        }
      }
    }
  }

  .outer {
    background-color: grey;
  }

  .inner {
    background-color: white;
  }
}

// range slider
.range-slider-track {
  width: 6px;
  height: 200px;
  background-color: #ccc;
  border-radius: 10px;

  .point {
    position: absolute;
    right: 0;
    width: 20px;
    height: 3px;
    background-color: var(--people-primary);
    pointer-events: none;
  }

  .text {
    position: absolute;
    left: 300%;
    width: 170px;
    font-size: 130%;
    pointer-events: none;
  }

  &.heat-track {
    background: var(--gradient-decathlon-themed);
    width: 25px;
    margin-left: 20px;
    border-radius: 18px;

    .point {
      right: 100%;
    }
  }
}

.range-slider-thumb {
  width: 20px;
  height: 10px;
  border-radius: 8px;
  background-color: rgb(194, 218, 226);
  outline: var(--people-primary);
}

@include screenSizeMobileFirst(lgMobile) {
  .range-slider-track {
    .text {
      font-size: 180%;
    } 
  }
}

@include screenSizeMobileFirst(ptTablet) {
  .range-slider-track {
    height: 350px;
  }
}