.modal-overlay {
    z-index: 1000;
    .modal-body {
        background: linear-gradient(to bottom, var(--people-primary-background) 35%, white 100%);
        color: var(--people-primary);

        .page-description {
            font-size: 100%;
            margin-bottom: 1em;
        }
    }
}