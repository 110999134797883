:root {
  // from brand guidelines
  --decathlon-primary: #007DBC;
  --decathlon-secondary: #FF600A;
  --decathlon-tertiary: #8C96A2;
  --decathlon-typography: white;
  --background-primary: #3643BA;
  --button-primary: #7AFFA6;
  --button-secondary: #3E2573;
  --button-positive: #3643BA;
  --people-primary: #004876;
  --people-primary-background: #CFE2F3;
  --people-primary-deep: #00263B;
  --performance-light: #FF985F;
  --performance-intense: #D14900;
  --know-dark: #018768;
  --know-light: #02D196;
  --grow-dark: #407AAD;
  --grow-light: #5EBAFF;
  --play-dark: #D14702;
  --play-light: #FF9855;
  --be-dark: #9C6D00;
  --be-light: #E3AA00;
  --accent: #7AFFA6;
  --gradient-primary: linear-gradient(to bottom, #007DBC 0%, #CFE2F3 100%);
  --gradient-primary-deep: linear-gradient(to bottom, #00263B 0%, #004876 100%);
  --gradient-intense: linear-gradient(to bottom, #D14900 0%, #FF985F 100%);
  --gradient-know: linear-gradient(to bottom, #018768 0%, #02D196 100%);
  --gradient-grow: linear-gradient(to bottom, #407AAD 0%, #5EBAFF 100%);
  --gradient-play: linear-gradient(to bottom, #D14702 0%, #FF9855 100%);
  --gradient-be: linear-gradient(to bottom, #9C6D00 0%, #E3AA00 100%);
  --gradient-heat-vertical: linear-gradient(180deg, rgba(214, 12, 2, 1) 0%, rgba(244, 123, 8, 1) 40%, rgba(26, 143, 82, 1) 100%);
  --gradient-decathlon-themed: linear-gradient(0deg, #1f005c, #23176c, #27297b, #2d3a8a, #344b98, #3d5ca6, #476db3, #547fbf, #6290cb, #71a1d7, #83b3e2, #95c4ed);
  // from designs

  // any others
  --background-button-negative: #dc3545;
  --background-form-input: white;
  --background-form-input-disabled: #f7f7f7;
  --background-form-multi-select-option: #eee;
  --stroke-form-input: #cccccc;
  --placeholder-form-input: #cdcdcd;
  --highlight-form-error: #ea7b73;
  --background-form-error: rgba(234, 123, 115, 0.18);
  --stroke-table-row: #cccccc;


  // colour keys

  --overall: rgb(108, 45, 171);
}

@mixin topicColorLight() {
  &.know {
      color: var(--know-light);
  }

  &.grow {
      color: var(--grow-light);
  }

  &.play {
      color: var(--play-light);
  }

  &.be {
      color: var(--be-light);
  }
}

@mixin topicColorDark() {
  &.know {
      color: var(--know-dark);
  }

  &.grow {
      color: var(--grow-dark);
  }

  &.play {
      color: var(--play-dark);
  }

  &.be {
      color: var(--be-dark);
  }
}

@mixin topicBackgroundColorLight() {
  &.know {
      background-color: var(--know-light);
  }

  &.grow {
      background-color: var(--grow-light);
  }

  &.play {
      background-color: var(--play-light);
  }

  &.be {
      background-color: var(--be-light);
  }
}

@mixin topicBackgroundColorDark() {
  &.know {
      background-color: var(--know-dark);
  }

  &.grow {
      background-color: var(--grow-dark);
  }

  &.play {
      background-color: var(--play-dark);
  }

  &.be {
      background-color: var(--be-dark);
  }
}

// fonts
@mixin mainFontThin() {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
}

@mixin mainFont() {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

@mixin mainFontMedium() {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

@mixin mainFontSemiBold() {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

@mixin mainFontBold() {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

@mixin mainTitleFont() {
  font-family: 'Inter', serif;
  font-weight: 700;
}

// screen size
@mixin screenSizeMobileFirst($size) {
  @if $size == lgDesktop {
    // large desktop
    @media only screen and (min-width: 1280px) {
      @content;
    }
  } @else if $size == mdDesktop {
    // medium desktop
    @media only screen and (min-width: 1100px) {
      @content;
    }
  } @else if $size == smDesktop {
    // small desktop
    @media only screen and (min-width: 1024px) {
      @content;
    }
  } @else if $size == lsTablet {
    // landscape tablet
    @media only screen and (min-width: 801px) {
      @content;
    }
  } @else if $size == ptTablet {
    // portrait tablet
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $size == lgMobile {
    // large mobile
    @media only screen and (min-width: 480px) {
      @content;
    }
  } @else if $size == mdMobile {
    // medium mobile
    @media only screen and (min-width: 414px) {
      @content;
    }
  } @else if $size == smMobile {
    // small mobile
    @media only screen and (min-width: 360px) {
      @content;
    }
  } @else {
    // custom size
    @media only screen and (min-width: $size) {
      @content;
    }
  }
}