.question-selector-wrapper {
    p {
      font-size: 130%;
    }

    .loading {
      font-size: 130%;
    }

  .question-selector {
    background-color: transparent;
    color: var(--decathlon-primary);

    i {
      font-size: 130%;
      margin-left: 20px;
      margin-right: 20px;
    }

    &.disabled {
      cursor: not-allowed;

      i {
        color: grey;  
      }
    }
  }
}

.question-break-selector {
  background-color: transparent;
  color: black;
  font-size: 180%;
 
  i {
    font-size: 150%;
    margin-left: 20px;
    margin-right: 20px;
  }

  &.disabled {
    cursor: not-allowed;

    i {
      color: grey;  
    }
  }
}

.question-break-selector-save-btn {
  z-index:1;
}

@include screenSizeMobileFirst(lgMobile) {
  .question-selector-wrapper {
    p {
      font-size: 160%;
    }

    .loading {
      font-size: 160%;
    }
  }
}