// table
table {
    tr {
        border-color: var(--stroke-table-row);
    }

    th {
        @include mainFontSemiBold;
    }
}

// pagination
.table-pagination {
    .page-links {
        button {
            color: var(--people-primary);
        }
    }
}

// search
.table-search {
    .icon {
        color: var(--typography-secondary)
    }

    input {
        @include mainFont();
        color: var(--people-primary);
        
        &::placeholder {
            color: var(--placeholder-form-input);
        }
    }
}