#check-results {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 75px;

    .pod {
        padding: {
            top: 40px;
            bottom: 40px;
        }
    }
}

@include screenSizeMobileFirst(lgMobile) {
    #check-results{
        .pod {
            margin-top: 0%;
        }
    }
}

@include screenSizeMobileFirst(ptTablet) {
    #check-results {
        .pod {
            margin-top: 25%;
        }
    }   
}

@include screenSizeMobileFirst(smDesktop) {
    .pod {
        margin-top: 35%;
    }
}

@include screenSizeMobileFirst(smDesktop) {
    .pod {
        margin-top: 0%;
    }
}

@include screenSizeMobileFirst(lgDesktop) {
    #check-results{
        justify-content: center;
        .background {
            height: 40vh;
        }

        .pod {
            margin-top: 0%;
        } 
    }
}
