// button
.button {
    @include mainFont();
    color: var(--decathlon-typography);
    background-color: var(--people-primary);
    border: 1px solid transparent;
    border-radius: 30px;

    &.primary {
        color: black;
        background-color: var(--button-primary);
    }

    &.secondary {
        color: var(--decathlon-typography);
        background-color: var(--button-secondary);
    }

    &.positive {
        color: var(--decathlon-typography);
        background-color: var(--button-positive);
    }

    &.negative {
        color: var(--decathlon-typography);
        background-color: var(--background-button-negative);
    }
}

.save-questionnaire-btn {
    margin: 20px 20px 30px;
  }