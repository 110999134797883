#assessment-complete {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 75px;

    .pod {
        padding: {
            top: 40px;
            bottom: 40px;
        }
    }
}

.graph-pods-wrapper {
    .flex-row:first-child {
        margin: 24px 0;
    }
}

.assessment-button {
    background-color: transparent;
    margin-bottom: 15px;
    margin-right: 15px;

    &:last-child() {
        margin-right: 0;
    }
}


@include screenSizeMobileFirst(lgMobile) {
    #assessment-complete {
        .pod {
            margin-top: 0%;
        }
    }
}

@include screenSizeMobileFirst(ptTablet) {
    #assessment-complete {
        .pod {
            margin-top: 25%;
        }
    }   
}

@include screenSizeMobileFirst(smDesktop) {
    .pod {
        margin-top: 35%;
    }
}

@include screenSizeMobileFirst(smDesktop) {
    .pod {
        margin-top: 0%;
    }
}

@include screenSizeMobileFirst(lgDesktop) {
    #assessment-complete {
        justify-content: center;
        .background {
            height: 40vh;
        }

    }
    #assessment-complete {
        .pod {
            margin-top: 0%;
        } 
    }
}
