#home-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 75px;
   
    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-size: cover;
    }

    .pod {
        margin-top: 70%;

        padding: {
            top: 40px;
            bottom: 40px;
        }
    }

    @include screenSizeMobileFirst(lgMobile) {
        .pod {
            margin-top: 50%;
        }
    }

    @include screenSizeMobileFirst(ptTablet) {
        .pod {
            margin-top: 40%;
        }
    }

    @include screenSizeMobileFirst(smDesktop) {
        .pod {
            margin-top: 30%;
        }
    }

    @include screenSizeMobileFirst(lgDesktop) {
        #home-page {
            justify-content: center;
        }

        .pod {
            margin-top: 20%;
        }
    }
}