.bubble-header {
    position: sticky;
    top: 0;
    z-index: 100;
    align-self: stretch;
    max-width: 100vw;

    .page-title {
        font-size: 260%;
        color: white;
        min-height: 1em;
        text-align: center;
        text-transform: uppercase;
        margin: 0;
        line-height: 1.1em;

        &.subtitle {
            font-size: 140%;
            text-transform: none;
            
            @include screenSizeMobileFirst(lgMobile) {
                font-size: 180%;
            }
        }
    }

    .content {
        padding: 0 25px 0 25px;
        background-color: var(--decathlon-secondary);
        margin-bottom: -35px;
        z-index: 2;

        @include screenSizeMobileFirst(lgMobile) {
            padding: 10px 25px 0 25px;
        }
    }

    .bottom-curve {
        background-color: var(--decathlon-secondary);
        border-radius: 0 0 50% 50%;
    }
    &.small-curve {
        .bottom-curve {
            min-height: 70px;
        }
        &.overlap {
            margin-bottom: -40px;
        }
    }
    &.medium-curve {
        .bottom-curve {
            min-height: 100px;
        }
        &.overlap {
            margin-bottom: -60px;
        }
    }
    &.large-curve {
        .bottom-curve {
            min-height: 120px;
        }
        &.overlap {
            margin-bottom: -80px;
        }
    }
}