.progress-bar-container {
    height: 20px;
    width: 100%;
    max-width: calc(100vw - 50px);
    background-color: #e0e0de;
    border-radius: 50px;
    margin: 10px 0;
    text-align: center;
    
    @include screenSizeMobileFirst(lgMobile) {
        margin: 20px 0;
    }

    .progress-bar {
        height: 100%;
        border-radius: inherit;
        text-align: center;
    }

    .text {
        color: white;
        font-weight: bold;
        font-size: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@include screenSizeMobileFirst(lgDesktop) {
    .progress-bar-container {
        height: 30px;
        max-width: 600px;
    }
}
