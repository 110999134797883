.secondary-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60vh;
        background-size: cover;
        object-fit: cover;
        
        @include screenSizeMobileFirst(ptTablet) {
            height: 50vh;
        }

        
        @include screenSizeMobileFirst(lgDesktop) {
            height: 58vh;
        }

}