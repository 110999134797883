.carousel-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    max-width: 100vw;

    .carousel-wrapper {
        max-width: 100vw;
        max-height: 70vh;
        height: fit-content;
        
        @include screenSizeMobileFirst(lgMobile) {
            max-height: 80vh;
        }
        

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 20px;
        }
    }

 }