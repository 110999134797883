.page-title {
    @include mainFontSemiBold;
}

.message-strip {
    background-color: var(--decathlon-tertiary);
    color: var(--decathlon-typography);
}

// page header (headings with orange divider)
.page-header {
    .divider {
        min-width: 5px;
        height: 25px;
        margin-right: 15px;
        background-color: var(--accent);

        &.long {
            height: 50px;
            bottom: 5px;
        }
    }

    .text {
        font-size: 120%;
        line-height: 1.25;

        strong {
            @include mainFontMedium;
        }
    }
}

// page description (general descriptions used in pods etc)
.page-description {
    font-size: 150%;
    margin-bottom: 0;

    strong {
        @include mainFontMedium;
    }

    &.centered {
        text-align: center;
    }

    &.secondary {
        color: var(--performance-intense);
    }
}

@include screenSizeMobileFirst(smMobile) {
    .page-header {
        .text {
            font-size: 120%;
   
        }
    }
}

@include screenSizeMobileFirst(lgMobile) {
    .page-header {
        .divider {
            &.long {
                height: 55px;
            }
        }
    }
}

@include screenSizeMobileFirst(smDesktop) {
    .page-header {
        .divider {
            height: 30px;
        }
    }
}

@include screenSizeMobileFirst(lgDesktop) {
    .page-header {
        .divider {
            height: 35px;
        }

        .text {
            line-height: 1.25;

            strong {
                @include mainFontMedium;
            }
        }
    }
}