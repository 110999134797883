#peers-invitation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 75px;

    .invite-button {
        margin-top: 10px;
    }

    .pod {
        padding: {
            top: 40px;
            bottom: 40px;
        }
    }

    .invited-peers-list {
        margin-top: 10px;
        margin-bottom: 30px;

        .header-text {
            font-size: 18px;
        }

        ul {
            list-style: none;

            li {
                padding: 10px;
                font-size: 16px;
                background-color: white;

                &:nth-child(even) {
                    background-color: #f2f2f2;
                }
            }
        }

        .button {
            font-size: 12px;
            padding: 6px 10px;
        }
    }

    .add-peer-invite-wrapper {
        .button {
            height: 50px;
            margin-bottom: 20px;
        }
    }

    .invite-peer-form-buttons {
        margin-bottom: 20px;
    }

    .invite-peer-form-buttons:last-child {
        margin-bottom: 0;
    }

    @include screenSizeMobileFirst(lgMobile) {
        #peers-invitation {
            .pod {
                margin-top: 30%;
            }
        }
    }
    
    @include screenSizeMobileFirst(lgDesktop) {
        #peers-invitation {
            justify-content: center;
            .background {
                height: 40vh;
            }
        }
    }
}

