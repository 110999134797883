#assessment-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-bottom: 75px;
    padding: 30px 20px 0;

    .bubble-header.assessment-header {
        margin-bottom: 25px;
    }

    .pod {
        padding: {
            top: 40px;
            bottom: 40px;
        }
        max-width: calc(100vw - 50px);
        min-width: calc(100vw - 50px);
        
        @include screenSizeMobileFirst(lgMobile) {
            min-width: 360px;
            max-width: 440px;
        }
    }

    .range-slider-pod {
        &.break {
            padding: 0 0 40px;
        }
    }

    .break-image {
        width: 100%;
        height: 300px;
        object-fit: cover;
        margin-bottom: 20px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    .sub-heading {
        font-size: 90%;
        margin-top: 8px;
    }

    @include screenSizeMobileFirst(smMobile) {
        .page-title {
            font-size: 200%;
        }
    }

    @include screenSizeMobileFirst(lgMobile) {
        .page-title {
            font-size: 260%;
        }
    }
}
.assessment-pod-link {
    width: 50%;
    text-decoration: unset;
    color: inherit;
    margin-bottom: 20px;

    &.bottom-row {
        margin-bottom: 0;
    }

    .assessment-pod {
        flex: 0.5;
        height: 260px;
        display: flex;
        flex-direction: column;
        margin: 0 8px;
        padding: 8px 10px;
    
        .title-wrapper {
            height: 60px; 
            display: flex;
            align-items: center;
    
            .page-title {
                font-size: 160%;
            }
    
            .page-title {
                margin: 0;
            }
        }
    }
}

.assessment-percentage-result {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-bottom: 4px;
    color: white;
    font-size: 18px;
}

.question-title {
    text-align: center;
    // min-height: 60px;
}

.question-text{
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    // min-height: 4.5em;
}

.question-break-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%; 
    height: calc(100vh - 80px);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 100;
}

@include screenSizeMobileFirst(lgMobile) {
    #assessment-page {
        .pod {
            margin-top: 0%;
        }

        .range-slider-pod {
            min-width: 360px;
        }
    }
}

@include screenSizeMobileFirst(ptTablet) {
    #assessment-page {
        .pod {
            margin-top: 0%;
        }
    }   
}

@include screenSizeMobileFirst(smDesktop) {
    .pod {
        margin-top: 0%;
    }
}

@include screenSizeMobileFirst(smDesktop) {
    .pod {
        margin-top: 0%;
    }
}

@include screenSizeMobileFirst(lgDesktop) {
    #assessment-page {
        .pod {
            margin-top: 0%;
        }
    }
}
