.pagination-dots-container {
    display: flex;
    justify-content: center;

    .pagination-dots {
        width: 140px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .line {
            position: absolute;
            background-color: white;
            top: calc(50% - 0.5px);
            height: 1px;
            left: 15px;
            right: 15px;

            &.prev {
                background-color: var(--decathlon-secondary);
                right: unset;
                transition: width 0.2s ease;
            }
        }

        .dot {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: white;
            transition: width 0.2s ease, height 0.2s ease;

            &.prev, &.selected {
                background-color: var(--decathlon-secondary);
            }

            &.selected {
                width: 30px;
                height: 30px;
            }
        }
    }
}