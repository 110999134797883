.pod {
    padding: 25px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0px 8px 15px 1px rgba(0,0,0,0.2);

    .page-header {
        margin-bottom: 15px;
        font-size: 140%;
    }

    .page-description {
        margin-bottom: 20px;
    }
    
    .remove-margin {
        margin-bottom: 0;
    }
}

.greeting-pod {
    width: 100%;
    margin-top: 100px;
}

.peer-invites-pod {
    width: 100%;
    margin-top: 100px;
}

.range-slider-pod {
    width: auto;
    .range-slider {
        margin-top: 40px;
        padding-left: 10em;
    }
}

@include screenSizeMobileFirst(lgMobile) {
    .pod {
        .page-header {
            font-size: 150%;
        }  

        .page-description {
            font-size: 180%;
        }
    }
}

@include screenSizeMobileFirst(smDesktop) {
    .range-slider-pod {
        width: 600px;
    }
}

@include screenSizeMobileFirst(lgDesktop) {
    .pod {
        .page-header {
            font-size: 200%;

            &.long {
                font-size: 130%;
            }
        }  

        .page-description {
            font-size: 200%;
        }
    }

    .greeting-pod {
        max-width: 600px;
        top: 100px;
    }

    .peer-invites-pod {
        max-width: 800px;
    }
}
